<template>
  <div class="min-h-screen h-full py-12 bg-hp-gray dark:bg-gray-900 dark:text-white">
    <div class="max-w-5xl px-4 mx-auto" style="min-height: 90vh;">
      <!-- Navigation -->
      <div class="flex justify-between items-center mb-40">
        <router-link to="/">
          <img src="@/assets/logos/Homeplan logo (new modern April 2022 - NO .com).svg" style="height: 2.1rem;" alt="Homeplan logo">
        </router-link>
        
        <router-link to="/" class="py-1.5 px-2.5 bg-blue-900 text-white transition ease-in duration-200 text-center text-sm font-semibold shadow-md focus:outline-none rounded-lg">
          Back to home
        </router-link>
      </div>

      <!-- Page heading -->
      <div class="text-6xl text-center font-bold mb-28">Fair chance</div>

      <div>
        Fair chance employment is a way to improve our communities by eliminating barriers for those with a criminal record and creating a pathway for a second chance. 
        Between 70-100 million Americans have a criminal record. 
        We at Homeplan believe that a criminal record should not block a person from gainful employment. 
        Companies like <a class="font-semibold text-hp-blue" target="_blank" href="https://checkr.com/">Checkr</a>, <a class="font-semibold text-hp-blue" target="_blank" href="https://slack.com/">Slack</a>, <a class="font-semibold text-hp-blue" target="_blank" href="https://www.honestjobs.co/">Honest Jobs</a>, <a class="font-semibold text-hp-blue" target="_blank" href="https://www.amazon.com/">Amazon</a>, <a class="font-semibold text-hp-blue" target="_blank" href="https://www.jpmorganchase.com/">JP Morgan Chase</a> and <a class="font-semibold text-hp-blue" target="_blank" href="https://www.starbucks.com/">Starbucks</a> practice fair chance employment. 
        To learn more download <a class="font-semibold text-hp-blue" target="_blank" href="https://checkr.com/resources/ebook/fair-chance-hiring-report">Checkr's Fair Chance Report</a>.

        <br/><br/>

        <span class="font-semibold">Four in Five U.S. Workers Want Employers to Hire People With Conviction Histories <a class="text-hp-blue" href="https://checkr.com/blog/fair-chance-hiring-report?utm_source=mkto&utm_medium=email&utm_campaign=checklist-january-2022&mkt_tok=MjA0LVdGSS01ODYAAAGB6LB9HEMfPLKD3KXSDt6ijwXS-q2RsAKidpKwb8WW8ZVRwqgB0w0AkyuDxojTtVigekQGg3CPq4k2VeVIxY8V3Tz6eFYXXdjDlKNZpEAt">[New Checkr Report]</a></span>.
      </div>
    </div>


    <div class="max-w 5xl mx-auto">
      <Footer />
    </div>
  </div>
</template>

<script>
import Footer from "@/components/footer.vue";

export default {
  components: {
    Footer
  },
}
</script>